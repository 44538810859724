:root {
  --tg-primary-dark3: #0f0f0f;
  --tg-primary-dark2: #0a0a0a;
  --tg-primary-dark1: #212121;
  /* --tg-primary-main: rgb(55, 114, 255); */
  --tg-primary-main: #f2295b;
  --tg-primary-main2: #e41348;
  --tg-primary-main-100: rgb(252, 219, 228);

  /* --tg-primary-main: #1f1f1f; */
  --tg-primary-light4: rgb(52, 53, 65);
  --tg-primary-light3: rgb(32, 33, 35);
  --tg-primary-light2: #0a0a0a;
  --tg-primary-light1: #0f0f0f;
  --tg-text-main: #212121;
  --tg-text-sub6: #797979;
  --tg-text-sub5: #bcbcbc;
  --tg-text-sub4: #e8e8e8;
  --tg-text-sub3: #f3f3f3;
  --tg-text-sub2: #fbfbfb;
  --tg-text-sub1: #ffffff;

  --tg-body-bg: rgb(255, 255, 255);
  --tg-secondary-color: rgba(33, 37, 41, 0.75);
  --tg-secondary-bg: rgb(233, 236, 239);
  --tg-territory-color: rgba(33, 37, 41, 0.5);
  --tg-emphasis-color: rgb(0, 0, 0);
  --tg-dark-color: rgb(33, 37, 41); /* 배경색 */
  --tg-dark-bg-subtle-color: rgb(206, 212, 218);

  --tg-info-color: #2fbcf2; /* 정보를 나타내는 밝은 파란색 */
  --tg-warning-color: #f2e229; /* 경고를 나타내는 밝은 노란색 */
  --tg-error-color: #f22f2f; /* 오류를 나타내는 진한 빨간색 */
  --tg-blue: #0d6efd;
  --tg-indigo: #6610f2;
  --tg-purple: #6f42c1;
  --tg-pink: #d63384;
  --tg-red: #dc3545;
  --tg-orange: #fd7e14;
  --tg-yellow: #fbff07;
  --tg-yellow-100: #fff9c2;
  --tg-yellow-200: #fff382;
  --tg-green: #198754;
  --tg-green-100: #d4edda;
  --tg-green-200: #c3e6cb;
  --tg-green-300: #b1dfbb;
  --tg-green-400: #a3dab9;
  --tg-green-500: #7bcf9e;
  --tg-green-600: #6cc48a;
  --tg-green-700: #4bb370;
  --tg-teal: #20c997;
  --tg-cyan: #0dcaf0;
  --tg-black: #000;
  --tg-white: #fff;
  --tg-gray: #6c757d;
  --tg-gray-dark: #343a40;
  --tg-gray-50: #f8f9fa;
  --tg-gray-100: #f8f9fa;
  --tg-gray-200: #e9ecef;
  --tg-gray-300: #dee2e6;
  --tg-gray-400: #ced4da;
  --tg-gray-500: #adb5bd;
  --tg-gray-600: #6c757d;
  --tg-gray-700: #495057;
  --tg-gray-800: #343a40;
  --tg-gray-900: #212529;
  --tg-primary-text-emphasis: #052c65;
  --tg-secondary-text-emphasis: #2b2f32;
  --tg-success-text-emphasis: #0a3622;
  --tg-info-text-emphasis: #055160;
  --tg-warning-text-emphasis: #664d03;
  --tg-danger-text-emphasis: #58151c;
  --tg-light-text-emphasis: #495057;
  --tg-dark-text-emphasis: #495057;

  --tg-border-width: 1px;
}

body {
  overflow-x: hidden;
}

.nav-category {
  gap: 5px;
}

.nav-category > .nav-link {
  border: 1px solid var(--tg-primary-main);
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border-radius: 5px; */
  /* transition: transform 0.5s ease-in-out; */
}

.card img:hover {
  transform: scale(1.1);
  transition: transform 0.5s ease-in-out;
  z-index: 10;
  box-shadow: 5px 10px 18px #888888;
  cursor: pointer;
}

.logo {
  height: 28px;
}

.swal2-icon {
  border-color: transparent !important;
}
.swal-custom-icon {
  height: 80px;
}

.swal2-confirm {
  background-color: var(--tg-primary-main) !important;
}

.swal2-confirm:focus {
  box-shadow: none !important;
}

.swal2-cancel {
  background-color: transparent !important;
  border: 1px solid var(--tg-gray-600) !important;
  color: var(--tg-primary-dark1) !important;
}

.swal2-title {
  font-size: 1.6rem !important;
}

.google-login {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.google-login iframe {
  opacity: 0;
  z-index: 9999;
}

.google-login .google-login-button {
  position: absolute;
  top: 0;
  cursor: pointer;
}

.ip-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
  width: 100%;
  /* max-width: 540px; */
  border: 1px solid var(--tg-text-sub5);
  border-radius: 10px;
  background-color: var(--tg-white);
  margin-bottom: 10px;
  box-shadow: 3px 3px 0 0 var(--tg-primary-main-100);
}

.nav-link.active {
  background-color: var(--tg-primary-main) !important;
  color: var(--tg-text-sub1) !important;
}

.nav-link {
  color: var(--tg-primary-main) !important;
}

.nav-link {
  cursor: pointer;
}

.nav-link:hover {
  background-color: var(--tg-primary-main) !important;
  color: var(--tg-text-sub1) !important;
}

.gslide-desc {
  cursor: pointer;
}

.swal2-container {
  z-index: 2000000 !important;
}

.header {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

header {
  width: 100%;
  /* position: fixed; */
  top: 0;
  width: "100%";
  z-index: 9999;
}

/* scroll이 0 이상일때만 header position:fixed, 그 외는 relative */
header.fixed {
  position: fixed;
  /* background-color: var(--tg-primary-dark1);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); */
}

/* mobile size */
@media (max-width: 768px) {
  .header {
    /* position clear */
    position: relative;
  }
}

/* .glightbox-container .gcontainer {
  z-index: 99 !important;
}

.glightbox-container .gslide.current {
  z-index: 999 !important;
}

.swal2-container {
  z-index: 9999;
} */

.text-white {
  color: var(--tg-text-sub1) !important;
}
